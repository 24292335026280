import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, Col, InputGroup } from "reactstrap"
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";

import { post, del, get, put } from "../../../../../helpers/api_helper";
import deleteIcon from "../../../../../assets/images/delete-icon.png";

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useDailyTip from "hooks/useDailyTip";
const EditModal = ({ rowData }) => {
    const axiosApi = useAxiosPrivate();
    const [uploading, setUploading] = useState(false);
    const [imageUrl, setImageUrl] = useState(rowData.image ? rowData.image : '');
    const [modal_center, setmodal_center] = useState(false);
    const { setRefresh, availableLanguageList } = useDailyTip();
    const { showToast } = useAuth();
    const [selectedStatus, setSelectedStatus] = useState(rowData.active == 1 ? true : false);
    const [submitLoading, setSubmitLoading] = useState(false);


    const addForm = (arrayHelpers, forms) => {
        let defaultAvailableLanguageType = null;
        let usedLanguageType = []
        if (forms.length > 0) {
            for (let i = 0; i < forms.length; i++) {
                if (forms[i].language) {
                    usedLanguageType.push(forms[i].language)
                }
            }
        }
        if (usedLanguageType.length > 0) {
            defaultAvailableLanguageType = availableLanguageList.filter((element) => !usedLanguageType.includes(element?.value));
        }
        else {
            defaultAvailableLanguageType = availableLanguageList;
        }

        let object = {
            language: defaultAvailableLanguageType[0]?.value,
            description: '',
            availableLanguage: defaultAvailableLanguageType,
        }
        arrayHelpers.push(object)
    }
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setSelectedStatus(rowData.active == 1 ? true : false);
        setImageUrl(rowData.image ? rowData.image : '')

    };

    const storeFiles = async (e) => {
        const { files } = e.target
        const validFiles = Array.from(files)
        setUploading(true);
        await Promise.all(
            validFiles.map(async (photo) => {
                const formData = new FormData();
                formData.append('image', photo)
                const response = await axiosApi.post('/api/admin/daily-tip/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                setImageUrl(response.data.url)
            })
        )
        setUploading(false)
    }

    const deleteImage = (e) => {
        setImageUrl('');
    }

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="lg">
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Daily Tip</ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{
                            descriptions: rowData.descriptions.map((desc, index) => {
                                const usedLanguages = rowData.descriptions.slice(0, index).map(d => d.language);
                                const filteredLanguages = availableLanguageList.filter(lang => !usedLanguages.includes(lang.value));
                                return {
                                    ...desc,
                                    availableLanguage: filteredLanguages
                                };
                            }),
                            id: rowData._id,
                            // category: rowData.category,
                        }}
                        validationSchema={Yup.object({
                            // category: Yup.string().required("Category is required"),
                            descriptions: Yup.array().of(
                                Yup.object().shape({
                                    language: Yup.string().required("Language is required"),
                                    description: Yup.string().required("Description is required"),

                                })
                            ).required('Languages is required').min(1, 'Languages is required')
                        })}
                        resetForm
                        onSubmit={async (values) => {
                            setSubmitLoading(true);
                            try {
                                const updatedValues = values.descriptions.map(desc => ({
                                    description: desc.description,
                                    language: desc.language ?? null
                                }));
                                // const opts = { ...values, descriptions: updatedValues, image: imageUrl, active: selectedStatus };
                                const opts = { ...values, descriptions: updatedValues, active: selectedStatus };
                                // console.log('opts', opts);

                                const response = await put('/api/admin/daily-tip/' + rowData._id, opts);
                                setRefresh(makeid(5));
                                tog_center();
                                showToast(response);
                            } catch (error) {
                                if (error.response && error.response.data && error.response.data.state) {
                                    showToast(error.response.data);
                                } else {
                                    let response = {};
                                    response.state = "error";
                                    response.toast = true;
                                    response.message = "Internal Server Error"
                                    showToast(response);
                                }
                            }
                            setSubmitLoading(false)
                        }}
                        render={({ values, touched, errors, setFieldValue }) => (
                            <Form>
                                <FieldArray
                                    name="descriptions"
                                    render={(arrayHelpers) => {
                                        const forms = values.descriptions;
                                        return (
                                            <div>
                                                {/* Category */}
                                                {/* <div className="mb-3">
                                                    <Label className="form-label">Category</Label>
                                                    <Input
                                                        tag={Field}
                                                        name="category"
                                                        style={{ width: '60%' }}
                                                        placeholder="Enter Category"
                                                        type="text"
                                                    />
                                                    <ErrorMessage component="div" className="error-text" name="category" />
                                                </div> */}
                                                <Row>
                                                    <Col lg={12} md={12}>
                                                        <div className="py-3 mb-3">
                                                            {touched.descriptions && errors.descriptions && typeof errors.descriptions === 'string' && (
                                                                <div className="error-text mb-3 px-3">{errors.descriptions}</div>
                                                            )}
                                                            {forms.map((form, index) => {
                                                                return <>
                                                                    <div className="" key={index}>
                                                                        {/* <Row className="mb-2 py-1" style={{ backgroundColor: '#F2F6F9', borderRadius: '10px' }}> */}
                                                                        <Row className="mb-2 py-1">
                                                                            <Col xl={10} lg={10} md={10}>
                                                                                {/* Language Type */}
                                                                                <div className="mb-3">
                                                                                    <Label className="form-label">Language</Label>
                                                                                    <div className="col">
                                                                                        <Dropdown
                                                                                            tag={Field}
                                                                                            name={`descriptions.${index}.language`}
                                                                                            value={form.language || ''}
                                                                                            onChange={e => {
                                                                                                setFieldValue(`descriptions.${index}.language`, e.target.value)
                                                                                            }}
                                                                                            disabled={index == 0}
                                                                                            options={form.availableLanguage}
                                                                                            optionLabel="name"
                                                                                            className="h-1 payment-status-dropdown"
                                                                                        />
                                                                                    </div>
                                                                                    <ErrorMessage component="div" className="error-text" name={`descriptions.${index}.language`} />

                                                                                </div>
                                                                                {/* Language Description */}
                                                                                <div className="mb-3">
                                                                                    <Label className="form-label">Description</Label>
                                                                                    <Input
                                                                                        tag={Field}
                                                                                        name="description"
                                                                                        placeholder="Enter Language Description"
                                                                                        component="textarea"
                                                                                        type="textarea"
                                                                                        maxLength="350"
                                                                                        rows="5"
                                                                                        onChange={(event) => {
                                                                                            const newValue = event.target.value;
                                                                                            setFieldValue(`descriptions.${index}.description`, newValue);
                                                                                        }}
                                                                                        value={form.description}
                                                                                    />
                                                                                    <ErrorMessage component="div" className="error-text" name={`descriptions.${index}.description`} />
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={2} lg={2} md={2}>
                                                                                {index > 0 &&
                                                                                    <div className="d-flex justify-content-end py-2">
                                                                                        <Button className="btn btn-danger" onClick={(event) => {
                                                                                            arrayHelpers.remove(index);
                                                                                        }}>
                                                                                            <i className="fas fa-times"></i>
                                                                                        </Button>
                                                                                    </div>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </>
                                                            })}
                                                            {forms.length > 0 && (
                                                                <div className="d-flex flex-wrap gap-2 justify-content-start">
                                                                    {forms.every(form => (
                                                                        form.language &&
                                                                        form.description
                                                                    )) ? (
                                                                        <Button onClick={() => { addForm(arrayHelpers, forms) }} color="primary" className="btn btn-primary">Add Translation</Button>
                                                                    ) : (
                                                                        <Button disabled color="primary" className="btn btn-primary">Add Translation</Button>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* Status */}
                                                <div className="mb-3 d-flex gap-2 align-items-center">
                                                    <Label className="form-label">Active : </Label>
                                                    <InputSwitch checked={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} />
                                                </div>
                                                {/* Photo */}
                                                {/* <div className="mb-3">
                                                    <div className="d-flex gap-2 align-items-center">
                                                        <Label className="form-label">Photo : </Label>
                                                        <label for="fileInput" className="btn btn-primary btn-sm">
                                                            {imageUrl == '' ? 'Add' : "Change"}
                                                        </label>
                                                        {uploading && <Label className="form-label text-danger">uploading...</Label>}
                                                        <input className="d-none" id="fileInput" type="file" multiple accept="image/jpeg, image/jpg, image/png" onChange={storeFiles} name="image" />
                                                    </div>
                                                    <div className="d-flex gap-2 box-images-container">
                                                        {imageUrl != '' && <div className="image-container">
                                                            <img src={deleteIcon} className="box-delete-icon" width={28} height={28} data-src={imageUrl} onClick={deleteImage}></img>
                                                            <img src={imageUrl} width={200} height={200} className="box-image"></img>
                                                        </div>}
                                                    </div>
                                                </div> */}
                                            </div>
                                        )
                                    }}
                                />
                                {/* Submit Button */}
                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                    <Button disabled={submitLoading} type="submit" color="primary">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        )}
                    />
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditModal
import React, { useState } from "react"
import moment from "moment"
import ContentTable from "./ContentTable";
import LoadingOverlay from 'react-loading-overlay';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import AddModal from './modals/AddModal';
import { Link } from "react-router-dom";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { InputText } from 'primereact/inputtext';
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
import DataExportCSV from "./exports/DataExportCSV";
import DeleteModal from "./modals/DeleteModal";
import ApprovalModal from "./modals/ApprovalModal";
import EditModal from "./modals/EditModal";
import DetailModal from "./modals/DetailModal";
import makeid from "helpers/random";
const DataTables = ({ id, lazyState, setlazyState, datas, totalRecords, expandedRows, setExpandedRows, loading, paramStatus, selected, setSelected, setRefresh }) => {
    const [search, setSearch] = useState(id || '')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 300;
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleSaleClick = () => {
        if (selected === 'sale') {
            setSelected(null); // Deselect if double-clicked
        } else {
            setSelected('sale'); // Select this button
        }
        setRefresh(makeid(5))
    };

    const handleInternalClick = () => {
        if (selected === 'internal') {
            setSelected(null); // Deselect if double-clicked
        } else {
            setSelected('internal'); // Select this button
        }
        setRefresh(makeid(5))
    };

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }
    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                {data.collection_detail_percentage == 100 && <div>
                    <ApprovalModal rowData={data} submitLoading={submitLoading} setSubmitLoading={setSubmitLoading} />
                </div>
                }
                <div>
                    {/* <AddDetailModal rowData={data} /> */}
                </div>
                {data.collection_detail_percentage == 0 &&
                    <div>
                        <EditModal rowData={data} />
                    </div>
                }
                {data.collection_detail_percentage == 0 &&
                    <div>
                        <DeleteModal rowData={data} />
                    </div>
                }
            </div>
        )
    }

    const inputDateTime = data => {
        return data.tour.tour_due_date ? moment(data.tour.tour_due_date).format("MM/DD/YYYY") : '-'
    }

    const inputProgress = data => {
        return data.collection_detail_percentage + '%'
    }

    const inputRealQuantity = data => {
        return paramStatus !== 'completed' ? data.collection_real_weight ?? 0 : <DetailModal data={data} />;

    }
    const columns = [
        // { field: '', header: '#', body: incrementId },
        { field: 'collection_code', header: 'Collection ID', body: '', className: '' },
        { field: 'collection_type', header: 'Type', body: '', className: '' },
        { field: 'destination_tank.tank_name', header: 'Destination', body: '', className: '' },
        // { field: 'max_capacity', header: 'Target Quantity', body: '', className: '' },
        { field: 'collection_real_weight', header: 'Actual Weight (kg)', body: inputRealQuantity },
        // { field: 'tour.tour_code', header: 'Tour ID', body: '', className: '' },
        // { field: 'tour.transport_code', header: 'Transport', body: '', className: '' },
        // { field: 'tour.person_name', header: 'Driver', body: '', className: '' },
        { field: '', header: 'Progress', body: inputProgress, className: '' },
        { field: 'collectionstatus_name', header: 'Status', body: '', className: '' },
        paramStatus == 'active' ? { field: '', header: 'Action', body: adminAction, className: '' } : {},
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }
    const rowExpansionTemplate = (data) => {
        return <>
            <div>
                <div>
                    <ul>
                        <li>Order ID : {data.order.order_code ? <Link to={'/orders'} state={data.order.order_code}> {data.order.order_code} </Link>
                            : '-'}</li>
                        <li>Transport : {data.tour.transport_code ? data.tour.transport_code !== "PUMP" ?
                            <Link to={'/assets?tab=4'} state={{ transport: data.tour.transport_code }}> {data.tour.transport_code} </Link>
                            : data.tour.transport_code
                            : '-'}</li>
                        <li>Driver : {data.tour.person_first_name ?
                            <Link to={'/assets?tab=5'} state={{ person: data.tour.person_first_name }}> {data.tour.person_first_name + " " + data.tour.person_last_name} </Link>
                            : '-'}</li>
                        <li>Due Date : {inputDateTime(data) ?? '-'}</li>
                        <li>Actual Weight (kg) : {inputRealQuantity(data) ?? '-'}</li>
                        <li>To Be Collected Quantity (kg): {data.max_capacity ?? '-'}</li>
                        <li>Status : {data.collectionstatus_name}</li>

                    </ul>
                </div>
                <ContentTable paramStatus={paramStatus} row={data} submitLoading={submitLoading} setSubmitLoading={setSubmitLoading}></ContentTable>
            </div>
        </>
    };
    return (
        <LoadingOverlay
            active={submitLoading}
            spinner
            text='Processing ...'
        >
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <Row className="align-items-center mb-2">
                                <Col md={3} className="d-flex flex-row align-items-center gap-3">
                                    {/* <AddModal /> */}
                                </Col>
                                <Col md={8} className="d-flex align-items-center justify-content-end flex-md-row flex-md-wrap">
                                <div className="d-flex ">
                                        <Button
                                            color={selected === 'sale' ? "primary" : "secondary"}
                                            onClick={handleSaleClick}
                                            className="me-4 mt-2 mb-2"
                                            style={{ minWidth: "100px" }}
                                        >
                                            Sale
                                        </Button>
                                        <Button
                                            color={selected === 'internal' ? "primary" : "secondary"}
                                            onClick={handleInternalClick}
                                            className="me-4 mt-2 mb-2"
                                            style={{ minWidth: "100px" }}
                                        >
                                            Internal
                                        </Button>

                                    </div>

                                    <span className="p-input-icon-left float-start float-md-end">
                                        <i className="pi pi-search" />
                                        <InputText value={search} onChange={onSearchTextChange} placeholder="Collection ID" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                    </span>
                                </Col>
                                <Col md={1}>
                                    <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                        <DataExportCSV paramStatus={paramStatus} lazyState={lazyState} />
                                    </div>
                                </Col>
                            </Row>

                            <DataTable
                                lazy
                                value={loading ? items : datas}
                                stripedRows
                                paginator
                                rows={lazyState.config.rows}
                                rowsPerPageOptions={ROWS_PER_PAGE}
                                size={'normal'}
                                onPage={onPage}
                                totalRecords={totalRecords}
                                first={lazyState.config.first}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                                expandedRows={loading ? null : expandedRows}
                                rowExpansionTemplate={rowExpansionTemplate}
                                onRowToggle={onRowToggle}
                                // rowClassName={rowClassName}
                                dataKey={loading ? '' : 'id'}
                            >
                                <Column expander={true} style={{ width: '3rem' }} />
                                {columns.map((col, i) => (
                                    <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                                ))}
                            </DataTable>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LoadingOverlay>
    )
}

export default DataTables

import React, { useState } from "react"
import { Link } from "react-router-dom"
import useAxiosPrivate from "hooks/useAxiosPrivate";
import avatar from "../../../../assets/images/users/user-blank.png";

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import AddModal from './modals/AddModal';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { InputText } from 'primereact/inputtext';
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM } from "constants/value";
import DataExportCSV from "./exports/DataExportCSV";
import useAsset from "hooks/useAsset";
import EditModal from "./modals/EditModal";
import DeleteModal from "./modals/DeleteModal";
import AddLicenseModal from "./modals/AddLicenseModal";
import ContentTable from "./ContentTable";
import { Divider } from "primereact/divider";
const DataTables = ({ id, lazyState, setlazyState }) => {
    const { personDatas, personTotalRecords, personExpandedRows, setPersonExpandedRows, personLoading, identityTypes } = useAsset();
    const [search, setSearch] = useState(id || '')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 300;

    const axiosApi = useAxiosPrivate();

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }


    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }
    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <AddLicenseModal row={data} />
                </div>
                <div>
                    <EditModal rowData={data} />
                </div>
                <div>
                    <DeleteModal rowData={data} />
                </div>
            </div>
        )
    }
    const adminName = (data) => {
        return (
            <div>
                <img
                    src={data?.photo || avatar}
                    alt=""
                    className="avatar-xs rounded-circle me-2"
                />{" "}
                {data.first_name + " " + data.last_name}
            </div>
        )
    }

    const getIdentityTypes = (data) => {
        return identityTypes.find(obj => obj.value === data.identity_type)?.name ?? '-'
    }
    const badgeStatus = (data) => {
        let badgeText = data.is_active ? "ACTIVE" : "INACTIVE";
        let badgeStatus = '';// Default badge text

        // Conditionally change badgeText based on status and isOpen
        if (data.is_active === true) {
            badgeStatus = `active`;
        } else {
            badgeStatus = `offline`;
        }
        return <>
            <span className={`badge bg-${badgeStatus} text-t-${badgeStatus}`}>
                {badgeText}
            </span>
        </>;
    }
    const companyName = (data) => {
        return data.company_name ?? data.company_id ?? "No Company";
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'person_code', header: 'ID', body: '', className: '' },
        { field: 'name', header: 'Name', body: adminName, className: '' },
        { field: '', header: 'Identity Type', body: getIdentityTypes, className: '' },
        { field: 'identity_number', header: 'ID Number', body: '', className: '' },
        { field: 'persontype_name', header: 'Type', body: '', className: '' },
        { field: 'company_name', header: 'Company', body: companyName, className: '' },
        { field: '', header: 'Status', body: badgeStatus, className: '' },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setPersonExpandedRows(e.data);
    }

    const rowClassName = (rowData) => {
        if (!rowData.is_active) {
            return 'redBg';
        }
    }

    const rowExpansionTemplate = (data) => {

        return <>
            <div className="d-flex flex-row justify-content-around p-3">
                <ul>
                    <li>Type: {data.persontype_name}</li>
                    <li>Company: {companyName(data)}</li>
                    <li>Name: {data.first_name + " " + data.last_name}</li>
                    <li>Email: {data.email}</li>
                    <li>Phone Number: {data.phone}</li>
                    <li>Identity Type: {getIdentityTypes(data)}</li>
                    <li>ID Number: {data.identity_number}</li>
                </ul>
                <Divider layout="vertical" />
                <ul>
                    <li>Country: {data.locationcountry_name}</li>
                    <li>Region: {data.locationregion_name}</li>
                    <li>City: {data.locationcity_name}</li>
                    <li>Address: {data.address}</li>
                    <li>Postcode: {data.postcode}</li>
                </ul>

            </div>

            <ContentTable row={data}></ContentTable>

        </>
    };
    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <AddModal />
                            </Col>
                            <Col md={5}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Name" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <DataExportCSV lazyState={lazyState} />
                                </div>
                            </Col>
                        </Row>

                        <DataTable
                            lazy
                            value={personLoading ? items : personDatas}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={personTotalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            expandedRows={personLoading ? null : personExpandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                            rowClassName={rowClassName}
                            dataKey={personLoading ? '' : 'id'}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column key={col.field} field={col.field} header={col.header} body={personLoading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default DataTables

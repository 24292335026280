import { createContext, useState } from "react";

const UserContext = createContext({});

export const UserProvider = ({children}) => {
    const [users, setUsers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [expandedRows, setExpandedRows] = useState(null);

    return (
        <UserContext.Provider value={{users, setUsers, totalRecords, setTotalRecords, expandedRows, setExpandedRows }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;
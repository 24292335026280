import React, { useEffect, useState } from "react";
import PartnerTable from "./PartnerTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePromotion from "hooks/usePromotion";
import useAuth from "hooks/useAuth";
import AddPartnerModal from "./modals/AddPartnerModal";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PaymentPeriodModal from "pages/PaymentPeriodModal";
import { Card, CardBody, Col, Row } from "reactstrap"

const AdminList = () => {
  const axiosApi = useAxiosPrivate();
  const { partnerRefresh, setPartners, setPartnerLoading, setPartnerTotalRecords, partnerLazyState, setPartnerLazyState } = usePromotion();
  const { auth } = useAuth();
  const [selectedPeriodName, setSelectedPeriodName] = useState('This Month');

  useEffect(() => {
    setPartnerLoading(true);

    const getPartners = async () => {
      try {
        // const response = await axiosApi.get(`/api/admin/marketing/partner?rows=${partnerLazyState.config.rows}&skip=${partnerLazyState.config.first}&searchText=${partnerLazyState.searchText}&startDate=${partnerLazyState.startDate}&endDate=${partnerLazyState.endDate}`);
        const response = await axiosApi.get(`/api/admin/marketing/partner?&skip=${partnerLazyState.config.first}&searchText=${partnerLazyState.searchText}&startDate=${partnerLazyState.startDate}&endDate=${partnerLazyState.endDate}`);
        setPartnerLoading(false);
        setPartners(response.data.result)
        setPartnerTotalRecords(response.data.totalRecords)

      } catch (err) {
        console.log(err);
      }
    }
    getPartners();

  }, [partnerRefresh, partnerLazyState]);
  document.title = `${process.env.REACT_APP_NAME || ''} | Partners`;

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={4} sm={4}>
                <h6 className="page-title">Partners</h6>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="/">Marketing</a>
                  </li>
                  <li className="active breadcrumb-item" aria-current="page">
                    Partners
                  </li>
                </ol>
              </Col>

              <Col
                md={8}
                sm={8}
                className="d-flex align-items-center justify-content-end flex-md-row flex-md-wrap"
              >
                <PaymentPeriodModal
                  mainOptions={partnerLazyState}
                  setMainOptions={setPartnerLazyState}
                  selectedPeriodName={selectedPeriodName}
                  setSelectedPeriodName={setSelectedPeriodName}
                />
              </Col>
            </Row>
          </div>
          <AddPartnerModal />

          <PartnerTable />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminList;

import React, { useState } from "react"
import { Link } from "react-router-dom"
import useAxiosPrivate from "hooks/useAxiosPrivate";
import DetailModal from "./modals/DetailModal";

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import AddModal from './modals/AddModal';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useUser from "hooks/useUser";
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM } from "constants/value";
import DataExportCSV from "./exports/DataExportCSV";
import useAsset from "hooks/useAsset";
import EditModal from "./modals/EditModal";
import DeleteModal from "./modals/DeleteModal";
import UnitDetailModal from "./modals/UnitDetailModal";
const DataTables = ({ id, lazyState, setlazyState }) => {
    const { transportDatas, transportTotalRecords, transportExpandedRows, setTransportExpandedRows, transportLoading } = useAsset();
    const [search, setSearch] = useState(id || '')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 300;

    const axiosApi = useAxiosPrivate();

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }


    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }
    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
    const getCompany = (data) => {
        return data.company_name ?? data.company_id;
    }

    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditModal rowData={data} />
                </div>
                <div>
                    <DeleteModal rowData={data} />
                </div>
            </div>
        )
    }

    const badgeStatus = (data) => {
        let badgeText = data.is_active ? "ACTIVE" : "INACTIVE";
        let badgeStatus = '';// Default badge text

        // Conditionally change badgeText based on status and isOpen
        if (data.is_active === true) {
            badgeStatus = `active`;
        } else {
            badgeStatus = `offline`;
        }
        return <>
            <span className={`badge bg-${badgeStatus} text-t-${badgeStatus}`}>
                {badgeText}
            </span>
        </>;
    }
    const badgeDestination = (data) => {
        let badgeText = data.is_destination ? "YES" : "NO";
        let badgeStatus = '';// Default badge text

        // Conditionally change badgeText based on status and isOpen
        if (data.is_destination === true) {
            badgeStatus = `active`;
        } else {
            badgeStatus = `offline`;
        }
        return <>
            <span className={`badge bg-${badgeStatus} text-t-${badgeStatus}`}>
                {badgeText}
            </span>
        </>;
    }
    // const parseCapacity = (data) => {
    //     //divide it by 1000 and add ton suffix
    //     let capacity = data.max_capacity;
    //     capacity = (capacity / 1000).toFixed(2);
    //     return capacity + " t";
    // }

    const parseCapacity = (data) => {
        //divide it by 1000 and add ton suffix
        return <UnitDetailModal data={data} />
    }
    const parseMaxCapacity = (data) => {
        //divide it by 1000 and add ton suffix
        let capacity = data.max_capacity;
        // capacity = (capacity / 1000).toFixed(2);
        return capacity;
    }
    const tourLogs = (data) => {
        return <DetailModal data={data} />

    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'transport_code', header: 'Transport ID', body: '', className: '' },
        { field: 'identify_number', header: 'Identification Number', body: '', className: '' },
        // { field: 'company_name', header: 'Company', body: getCompany, className: '' },
        { field: '', header: 'Capacity', body: parseMaxCapacity, className: '' },
        { field: '', header: 'Quantity', body: parseCapacity, className: '' },
        // { field: 'locationcountry_name', header: 'Country', body: '', className: '' },
        { field: 'transporttype_name', header: 'Type', body: '', className: '' },
        { field: '', header: 'Routes/Tours', body: tourLogs, className: '' },
        { field: '', header: 'Set As Destination', body: badgeDestination, className: '' },

        { field: '', header: 'Status', body: badgeStatus, className: '' },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setTransportExpandedRows(e.data);
    }

    const rowClassName = (rowData) => {
        if (!rowData.is_active) {
            return 'redBg';
        }
    }
    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <div className="d-flex flex-row justify-content-start p-3">
                    <div>
                        <ul>
                            <li>Transport ID : {data.transport_code}</li>
                            <li>Company ID : {getCompany(data)}</li>
                            <li>Capacity : {parseMaxCapacity(data)}</li>
                            <li>Quantity : {parseCapacity(data)}</li>
                            <li>Identification Number : {data.identify_number}</li>
                            <li>Type : {data.transporttype_name}</li>
                            <li>Country : {data.locationcountry_name}</li>
                        </ul>
                    </div>
                    {/* <Divider layout="vertical" />
                    <div>
                        <ul>
                            <li>Contact Email : {data.contact_email}</li>
                            <li>Contact Number : {data.contact_number}</li>

                        </ul>
                    </div> */}
                </div>

            </div>
        );
    };

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <AddModal />
                            </Col>
                            <Col md={5}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="ID / Identification Number" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <DataExportCSV lazyState={lazyState} />
                                </div>
                            </Col>
                        </Row>

                        <DataTable
                            lazy
                            value={transportLoading ? items : transportDatas}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={transportTotalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            expandedRows={transportLoading ? null : transportExpandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                            rowClassName={rowClassName}
                            dataKey={transportLoading ? '' : '_id'}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column key={col.field} field={col.field} header={col.header} body={transportLoading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default DataTables

import { createContext, useState } from "react";

const TransactionContext = createContext({});

export const TransactionProvider = ({ children }) => {
    const [expandedRows, setExpandedRows] = useState(null);
    const [rejectedClicked, setRejectedClicked] = useState(false);
    const [bonusClicked, setBonusClicked] = useState(false);

    return (
        <TransactionContext.Provider value={{ expandedRows, setExpandedRows, rejectedClicked, setRejectedClicked, bonusClicked, setBonusClicked}}>
            {children}
        </TransactionContext.Provider>
    )
}

export default TransactionContext;
import { useState } from "react";
import { Col, Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import { InputSwitch } from 'primereact/inputswitch';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../../helpers/api_helper";
import useAuth from "hooks/useAuth";

import '../../../../../assets/css/style.css'
import useEducard from "hooks/useEducard";

const EditSectionModal = ({ section }) => {
    const [status, setStatus] = useState(section.status == 1 ? true : false);

    const { showToast } = useAuth();
    const { setRefresh, levelOptions } = useEducard();
    const [modal_center, setmodal_center] = useState(false);
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: section._id,
            title_en: section.title_en,
            title_id: section.title_id,
            order: section.order,

        },
        validationSchema: Yup.object().shape({
            title_en: Yup.string().required(
                "This value is required"
            ),
            title_id: Yup.string().required(
                "This value is required"
            ),
            order: Yup.number().transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value).moreThan(0, 'Order must be greater than 0').required(
                "This value is required"
            ),

        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const { title_en, title_id, order, id } = values;
                const obj = { title_en, title_id, order, status: status == true ? 1 : 0 }

                const response = await put('/api/admin/education/education-section/' + id, obj);
                setRefresh(response.random);
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });


    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Section</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div className="mb-3">
                            <Label className="form-label">English Title</Label>
                            <Input
                                name="title_en"
                                placeholder="Enter English Title"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title_en || ""}
                                invalid={
                                    validation.touched.title_en && validation.errors.title_en ? true : false
                                }
                            />
                            {validation.touched.title_en && validation.errors.title_en ? (
                                <FormFeedback type="invalid">{validation.errors.title_en}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Indonesian Title</Label>
                            <Input
                                name="title_id"
                                placeholder="Enter Indonesian Title"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title_id || ""}
                                invalid={
                                    validation.touched.title_id && validation.errors.title_id ? true : false
                                }
                            />
                            {validation.touched.title_id && validation.errors.title_id ? (
                                <FormFeedback type="invalid">{validation.errors.title_id}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Order</Label>
                            <Input className="col-3"
                                name="order"
                                placeholder="Enter Order Number"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.order || ""}
                                invalid={
                                    validation.touched.order && validation.errors.order ? true : false
                                }
                            />

                            {validation.touched.order && validation.errors.order ? (
                                <FormFeedback type="invalid">{validation.errors.order}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-5">
                            <h4 className="card-title mb-3">Active</h4>
                            <InputSwitch checked={status} onChange={(e) => setStatus(e.value)} />
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Save
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditSectionModal

import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup,InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"
import useAuth from "hooks/useAuth";
import Select from "react-select";

import axiosApi from 'helpers/api_helper';
const UserEditReferralModal = ({ user, setRefresh }) => {
    const { showToast } = useAuth();

    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedCode, setSelectedCode] = useState(null);
    const [submittedReffCode,setSubmittedReffCode] = useState("");
    const [reffCodes, setReffCodes] = useState([]);
    const toggleModal = () => {
        getReffCodes();
        
        // setSelectedCode(user?.referral_code?.toUpperCase() ?? "");
        
        setModal(!modal);
    }

    const getReffCodes = async () => {
        const response = await axiosApi.get(`/api/admin/marketing/referral`);
        if (response) {
            const result = response.data.result;
            var codes = [];
            //foreach result. pluck code field (after uppercased) to codes
            codes.push({code:"No Referral Code",realCode:""})
            result.forEach(element => {
                codes.push( element);
            });
            setReffCodes(codes);


            var reffCode = null;
            if (user?.referral_code) {
                reffCode = user.referral_code.toUpperCase();
            }
            //check if reffcode with that specific code is exist on reffcodes
            if (reffCodes.length > 0) {
                var found = reffCodes.find((element) => element.code == reffCode);
                if (found) {
                    console.log("found", found);
                    setSelectedCode(found);
                    setSubmittedReffCode(found.code ?? "");
                }
                else{
                    setSelectedCode("No Referral Code");
                }
            } else {
                setSelectedCode("No Referral Code");
            }
        }
    }

    const changeStatus = (e) => {
        setSelectedCode(e.value);
        setSubmittedReffCode(e.code ?? "");
    }

    //onmount changeStatus
   

    const handleConfirm = async () => {
        const opts = { user_id:user._id , referral_code: (submittedReffCode == "No Referral Code" ? "" : (submittedReffCode ?? "") )};

        try {
            const response = await axiosApi.post(`/api/admin/support/userReferral`, opts);
            setRefresh(response.data.random )
            showToast(response.data);
            toggleModal();
     
        } catch (e) {
            console.log("error while submitting",e)
        }
    };

    const showReffCode = () => {
        var rc = "No Referral Code"
        console.log("u",user);
        if (user?.referral_code != null && user?.referral_code != "" ) {
            rc = user?.referral_code?.toUpperCase();
        }
        return rc ;
    }

    return (
        <>
        
            <a href="javascript:void(0)"role="button" onClick={toggleModal}>{showReffCode()}</a>
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Edit User ({user.username}) Referral Code</ModalHeader>
                <ModalBody>
            
                    <div className="mb-3">
                            <Label className="form-label">Referral Code:</Label><br />
                            <Select
                                value={selectedCode}
                                onChange={changeStatus}
                                options={reffCodes} // Map the array of objects to an array of options
                               
                                getOptionLabel={option => option.code}
                                getOptionValue={option => "test"}
                                classNamePrefix="select2-selection"
                            />
                            {/* <Dropdown
                                value={selectedBox}
                                onChange={changeStatus}
                                options={boxes} // Map the array of objects to an array of options
                                optionLabel="label"
                                className="h-1 payment-status-dropdown"
                            /> */}
                            {/* <Dropdown
                                isOpen={dropdownOpen}
                                toggle={() => setDropdownOpen(!dropdownOpen)}
                                className="mt-4 mt-sm-0"

                            >   <DropdownToggle className="btn btn-secondary" caret>
                                    {selectedBox ? selectedBox : "Select a box"} <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {boxes.map((box) => (
                                        <DropdownItem
                                            key={box.id}
                                            onClick={() => handleSelectChange(box)}
                                        >
                                            {box.name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>

                            </Dropdown> */}
                       
                        </div>
                </ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button color="primary" disabled={loading} onClick={handleConfirm}>
                        Confirm
                    </Button>

                </ModalFooter>
            </Modal>
        </>
    );
};

export default UserEditReferralModal

export const TON_TO_LITER = 1087
export const ROWS_PER_PAGE = [5, 10, 25, 50, 100]
export const ROWS_PER_PAGE_MEDIUM_DASHBOARD = [25, 50, 100]
export const ROWS_PER_PAGE_MEDIUM = [20, 50, 100]
export const ROWS_PER_PAGE_LARGE = [50, 100, 200]
export const phoneRegExp = /^[\+0-9]?[1-9]{1,4}[ \-]*(\([0-9]{2,3}\)[ \-]*|[0-9]{2,4}[ \-]*)*[0-9]{3,4}[ \-]*[0-9]{3,4}?$/
export const positiveNumericRegExp = /^[0-9]+$/
export const MONTHS_IN_YEAR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
export const INITIAL_OPENING_HOURS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
].map(day => ({
  day,
  isOpen: false,
  open: "00:00",
  close: "00:00",
  timezone: "WIB",
}))
export const ENV_IS_PROD = process.env.REACT_APP_ENV === "prod"
export const CURRENT_WITHDRAWAL_PLATFORM = "XENDIT"

import React from "react"
import { Link } from "react-router-dom"
import { ROWS_PER_PAGE } from "constants/value";
import { useState } from "react";
import { Divider } from 'primereact/divider';

import { InputText } from 'primereact/inputtext';
import { debounce, simplify, balanceFormatter } from "helpers/functions";

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import useIncident from "hooks/useIncident";
import DataExportCSV from "./exports/DataExportCSV";
import DetailModal from "./modals/DetailModal";
const DataTables = ({ id, lazyState, setLazyState }) => {
    const { datas, loading, totalRecords, } = useIncident();
    const [expandedRows, setExpandedRows] = useState(null);
    const items = Array.from({ length: 5 }, (v, i) => i);
    const [search, setSearch] = useState(id || '')
    const delay = 300;

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setLazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const roundedVol = (data) => {
        return simplify(data.totalVolume)
    }
    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setLazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const inputCurrentWeight = data => {
        return <DetailModal data={data} />;
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'incident_code', header: 'ID', body: '' },
        { field: 'tank_name', header: 'Box / Tank', body: '' },
        { field: 'ucounitstatus_name', header: 'Status', body: '' },
        { field: 'description', header: 'Reason', body: '' },
        { field: 'previous_weight', header: 'Previous Weight', body: '' },
        { field: 'lost_weight', header: 'Lost Weight', body: '' },
        { field: '', header: 'Current Weight', body: inputCurrentWeight },
    ]

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">

                                {/* <h4 className="card-title mb-0">Users</h4> */}
                            </Col>
                            <Col md={5}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="ID" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <DataExportCSV lazyState={lazyState} />

                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            lazy
                            value={loading ? items : datas}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE}
                            size={'normal'}
                            onPage={onPage}
                            // expandedRows={loading ? null : expandedRows}
                            // rowExpansionTemplate={rowExpansionTemplate}
                            // onRowToggle={onRowToggle}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            dataKey={loading ? '' : '_id'}

                        >
                            {columns.map((col, i) => (
                                <Column sortable={col.sortable} key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default DataTables

import React, { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import EditSectionContentModal from "./modals/EditSectionContentModal";
import DeleteSectionContentModal from "./modals/DeleteSectionContentModal";
import PreviewImageModal from "./modals/PreviewImageModal";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useLocation } from "react-router-dom";
import useEducard from "hooks/useEducard";

const ContentTable = ({ section }) => {
    const location = useLocation();
    const propsData = location.state;
    const [contents, setContents] = useState([]);

    const { setTotalRecords, totalRecords, setExpandedRows, refresh } = useEducard()
    const items = Array.from({ length: 5 }, (v, i) => i);

    const [lazyState, setlazyState] = useState({
        searchText: propsData || '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();

    const getEducardContents = async () => {
        try {
            const response = await axiosApi.get(`/api/admin/education/education-section/${section._id}/education-content?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            // console.log(response)

            setContents(response.data.result);
            // setTotalRecords(response.data.totalRecords)
            setLoading(false);
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                setExpandedRows(obj)
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">

                <div>
                    <EditSectionContentModal content={data} />
                </div>
                <div>
                    <DeleteSectionContentModal content={data} />
                </div>
            </div>
        )
    }
    const badgeStatus = (data) => {
        var badgetText = 'danger'
        var statusText = 'non active'
        if (data.status == true) {
            statusText = 'active'
            badgetText = 'success'
        }
        return (
            <span className={`badge bg-${badgetText} text-t-${badgetText}`}>
                {statusText}
            </span>
        )
    }
    const imagePreview = (data) => {
        if (data.image) {
            return <PreviewImageModal data={data}></PreviewImageModal>

        } else {
            return null
        }


    }

    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'title_en', header: 'English Title', body: '' },
        { field: 'title_id', header: 'Indonesian Title', body: '' },
        { field: 'description_en', header: 'English Description', body: '' },
        { field: 'description_id', header: 'Indonesian Description', body: '' },
        { field: 'order', header: 'Order', body: '' },
        { field: 'image', header: 'Image', body: imagePreview },
        { field: 'status', header: 'Status', body: badgeStatus, className: '' },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getEducardContents();
        }, 300);
    }, [lazyState]);

    useEffect(() => {
        getEducardContents();
    }, [refresh]);
    return (
        <React.Fragment>
            <DataTable
                value={loading ? items : contents}
                stripedRows
                size={'normal'}
                scrollable
                scrollHeight="400px"
            >
                {columns.map((col, i) => (
                    <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                ))}
            </DataTable>

        </React.Fragment>
    );
};

export default ContentTable;

import { useState } from "react";
import { Col, Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import { InputSwitch } from 'primereact/inputswitch';
import { Divider } from "primereact/divider";
import deleteIcon from "../../../../../assets/images/delete-icon.png";
import axiosApi from "helpers/api_helper";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import useAuth from "hooks/useAuth";

import '../../../../../assets/css/style.css'
import useEducard from "hooks/useEducard";
import makeid from "helpers/random";

const EditSectionContentModal = ({ content }) => {
    console.log(content)
    const [status, setStatus] = useState(content.status == 1 ? true : false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(content.image ? content.image : '');
    const { showToast } = useAuth();
    const { setRefresh, levelOptions } = useEducard();
    const [modal_center, setmodal_center] = useState(false);
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const deleteImage = (e) => {
        setPreview('');
        setSelectedFile(null)
    }
    // For
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: content._id,
            title_en: content.title_en,
            description_en: content.description_en,
            title_id: content.title_id,
            description_id: content.description_id,
            order: content.order
        },
        validationSchema: Yup.object().shape({
            title_en: Yup.string().required(
                "This value is required"
            ),
            title_id: Yup.string().required(
                "This value is required"
            ),
            description_en: Yup.string().required(
                "This value is required"
            ),
            description_id: Yup.string().required(
                "This value is required"
            ),
            order: Yup.number().transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value).moreThan(0, 'Order must be greater than 0').required(
                "This value is required"
            ),
            // image: Yup.mixed().required('File is required'), // Validate the file field

        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const formData = new FormData();
                formData.append('section_id', content.section_id._id);
                formData.append('title_en', values.title_en);
                formData.append('title_id', values.title_id);
                formData.append('description_en', values.description_en);
                formData.append('description_id', values.description_id);
                formData.append('order', values.order);
                formData.append('status', status == true ? 1 : 0);

                // Append image file to the FormData
                if (selectedFile) {
                    formData.append('image', selectedFile); // Assuming 'image' is the name of the file input field
                }
                // console.log(formData.get('image'))
                const response = await axiosApi.put('/api/admin/education/education-content/' + content._id, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })


                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response.data);
                // setPreview('');
                // setSelectedFile(null)
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });


    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="lg">
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Content</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <h5>English</h5>
                        <Divider></Divider>
                        <div className="mb-3">
                            <Label className="form-label"> Title</Label>
                            <Input
                                name="title_en"
                                placeholder="Enter English Title"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title_en || ""}
                                invalid={
                                    validation.touched.title_en && validation.errors.title_en ? true : false
                                }
                            />
                            {validation.touched.title_en && validation.errors.title_en ? (
                                <FormFeedback type="invalid">{validation.errors.title_en}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label>Description</Label>
                            <Input
                                name="description_en"
                                placeholder="Enter Description"
                                rows="5"
                                type="textarea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description_en || ""}
                                invalid={
                                    validation.touched.description_en && validation.errors.description_en ? true : false
                                }
                            />
                            {validation.touched.description_en && validation.errors.description_en ? (
                                <FormFeedback type="invalid">{validation.errors.description_en}</FormFeedback>
                            ) : null}
                        </div>

                        <h5>Indonesian</h5>
                        <Divider></Divider>

                        <div className="mb-3">
                            <Label>Indonesian Title</Label>
                            <Input
                                name="title_id"
                                placeholder="Enter Indonesian Title"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title_id || ""}
                                invalid={
                                    validation.touched.title_id && validation.errors.title_id ? true : false
                                }
                            />
                            {validation.touched.title_id && validation.errors.title_id ? (
                                <FormFeedback type="invalid">{validation.errors.title_id}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Description</Label>
                            <Input
                                name="description_id"
                                placeholder="Enter Description"
                                rows="5"
                                type="textarea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description_id || ""}
                                invalid={
                                    validation.touched.description_id && validation.errors.description_id ? true : false
                                }
                            />
                            {validation.touched.description_id && validation.errors.description_id ? (
                                <FormFeedback type="invalid">{validation.errors.description_id}</FormFeedback>
                            ) : null}
                        </div>

                        <Divider></Divider>
                        <div className="mb-3">
                            <Label className="form-label">Order</Label>
                            <Input className="col-3"
                                name="order"
                                placeholder="Enter Order Number"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.order || ""}
                                invalid={
                                    validation.touched.order && validation.errors.order ? true : false
                                }
                            />

                            {validation.touched.order && validation.errors.order ? (
                                <FormFeedback type="invalid">{validation.errors.order}</FormFeedback>
                            ) : null}
                        </div>

                        <div className="row">
                            <div className="col-2">
                                <div className="mb-3">
                                    <div className="mb-3">
                                        <h4 className="card-title mb-3">Active</h4>
                                        <InputSwitch checked={status} onChange={(e) => setStatus(e.value)} />
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <Label className="form-label">Images</Label>
                                        {/* <input className="d-none" id="fileInput" type="file" multiple accept="image/jpeg, image/jpg, image/png" onChange={handleFileInputChange} name="image" /> */}
                                    </div>
                                    <div className="d-flex gap-2 align-items-center mb-3">
                                        <input className="form-control" id="image" type="file" multiple accept="image/jpeg, image/jpg, image/png" onChange={handleFileInputChange} name="image" />
                                        {/* <label for="fileInput" className="btn btn-primary btn-sm">
                                            Add Image
                                        </label> */}
                                    </div>

                                </div>
                            </div>
                            {validation.touched.image && validation.errors.image ? (
                                <FormFeedback type="invalid">{validation.errors.image}</FormFeedback>
                            ) : null}
                            <div className="col-10">
                                {preview && (
                                    <div className="mb-3">
                                        <div className="d-flex gap-2 box-images-container">
                                            <div className="image-container">
                                                {/* <img src={deleteIcon} className="box-delete-icon" width={28} height={28} onClick={deleteImage}></img> */}
                                                <img src={preview} width={200} height={200} className="box-image" alt="Preview" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Save
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditSectionContentModal

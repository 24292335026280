import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import classnames from "classnames";
import TierSettings from "./BonusTier";
import BalanceAlerts from "./balanceAlerts";

class UiTabsAccordions extends Component {
    constructor(props) {
        super(props);
        // Parse URL parameters to determine the active tab
        const urlParams = new URLSearchParams(window.location.search);
        this.state = {
            activeGeneralTab: urlParams.get("tab") || "1"
        };

        this.toggleJustidyCustom = this.toggleJustidyCustom.bind(this);
    }

    toggleJustidyCustom(tab) {
        if (this.state.activeGeneralTab !== tab) {
            // Update URL with the new active tab
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("tab", tab);
            window.history.pushState({}, "", `${window.location.pathname}?${searchParams.toString()}`);

            // Update state with the new active tab
            this.setState({
                activeGeneralTab: tab
            });
        }
    }

    render() {
        document.title = `${process.env.REACT_APP_NAME || ""} | General`;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <Breadcrumbs title="Settings" breadcrumbItem="General" />
                            </Col>
                            <Col md={4}></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Nav tabs justified className="nav-tabs-custom">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: this.state.activeGeneralTab === "1"
                                                    })}
                                                    onClick={() => {
                                                        this.toggleJustidyCustom("1");
                                                    }}
                                                >
                                                    <span className="d-sm-block">Bonus Tiers</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: this.state.activeGeneralTab === "2"
                                                    })}
                                                    onClick={() => {
                                                        this.toggleJustidyCustom("2");
                                                    }}
                                                >
                                                    <span className="d-sm-block">Balance Alerts</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>

                                        <TabContent activeTab={this.state.activeGeneralTab} className="p-3 text-muted">
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col sm="12">
                                                        <TierSettings />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col sm="12">
                                                        <BalanceAlerts />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default UiTabsAccordions;

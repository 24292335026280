import { useState } from "react";
import {
    Button, Input, Modal, ModalBody, ModalHeader, Col, Row,
    Dropdown,
    DropdownMenu,
    DropdownItem, DropdownToggle,
} from "reactstrap"
// import { Dropdown } from 'primereact/dropdown';
import makeid from 'helpers/random';
import { getFormattedUTCDate } from 'helpers/functions';


import moment from "moment";

import { DayPickerRangeController } from 'react-dates';

// import servicesIcon3 from "../../../../assets/images/services-icon/03.png";
import "../assets/scss/date_range_picker.scss";
import useAuth from "hooks/useAuth";


const PaymentPeriodModal = ({ mainOptions, setMainOptions, selectedPeriodName, setSelectedPeriodName }) => {
    const [btnsecondary1, setBtnsecondary1] = useState(false);

    const [fontSize, setFontSize] = useState('font-size-24 pb-1');
    const isOutsideRange = day =>
        moment.utc().local().diff(day.startOf('day')) < 0;

    const { filterPeriod } = useAuth();

    const [options, setOptions] = useState({
        startDate: null,
        endDate: null,
        focusedInput: 'startDate',
    })

    const [modal_center, setmodal_center] = useState(false);
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const onDatesChange = ({ startDate, endDate }) => {
        setOptions(prevOptions => ({
            ...prevOptions,
            startDate,
            endDate
        }))
    }

    const onFocusChange = (focusedInput) => {
        setOptions(prevOptions => ({
            ...prevOptions,
            focusedInput: !focusedInput ? 'startDate' : focusedInput
        }))
    }

    const changePeriod = (e) => {

        var startDate = '';
        var endDate = '';

        const selectedOption = filterPeriod.find(option => option.value === e.value);
        setSelectedPeriodName(selectedOption.name)
        const today = new Date();
        if (e.value == 'today') {
            endDate = getFormattedUTCDate(today)
            const startDateToday = new Date();

            startDateToday.setHours(0, 0, 0, 0)
            startDate = getFormattedUTCDate(startDateToday)
        } else if (e.value == 'week') {
            const now = new Date();
            now.setHours(0, 0, 0, 0)
            const sevenDaysAgo = new Date(now.getTime() - (6 * 24 * 60 * 60 * 1000))
            startDate = getFormattedUTCDate(sevenDaysAgo)
            endDate = getFormattedUTCDate(today)

        } else if (e.value == 'month-ago') {
            const now = new Date();
            now.setHours(0, 0, 0, 0)
            const sevenDaysAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000))
            startDate = getFormattedUTCDate(sevenDaysAgo)
            endDate = getFormattedUTCDate(today)

        } else if (e.value == 'month') {
            const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
            endOfMonth.setHours(23, 59, 59, 999)
            startDate = getFormattedUTCDate(startOfMonth)
            endDate = getFormattedUTCDate(endOfMonth)
        } else if (e.value == 'lastMonth') {
            const today = new Date();
            const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const firstDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
            firstDayOfPreviousMonth.setMonth(firstDayOfCurrentMonth.getMonth() - 1);

            const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
            lastDayOfPreviousMonth.setHours(23, 59, 59, 999)
            lastDayOfPreviousMonth.setDate(0); // Sets to the last day of the previous month
            startDate = getFormattedUTCDate(firstDayOfPreviousMonth)
            endDate = getFormattedUTCDate(lastDayOfPreviousMonth)
        } else if (e.value == 'year') {
            const startOfYear = new Date(today.getFullYear(), 0, 1); // January 1st of the current year
            startDate = getFormattedUTCDate(startOfYear)
            endDate = getFormattedUTCDate(today)
        } else if (e.value == 'custom') {
            tog_center()
        }
        setFontSize('font-size-24 pb-1')
        let _filters = { ...mainOptions };
        _filters.startDate = startDate;
        _filters.endDate = endDate;
        setMainOptions(_filters)
    }

    const convertStringToDate = (dateString) => {
        // Split the date string into day, month, and year parts
        const [day, month, year] = dateString.split('-').map(Number);

        // Create a Date object using the year, month (zero-based), and day values
        const dateObject = new Date(year, month - 1, day); // Subtract 1 from the month (as it's zero-based)
        return dateObject;
    }

    const convertStringToDateWithEndTime = (dateString) => {
        // Split the date string into day, month, and year parts
        const [day, month, year] = dateString.split('-').map(Number);

        // Create a Date object using the year, month (zero-based), and day values
        const dateObject = new Date(year, month - 1, day); // Subtract 1 from the month (as it's zero-based)
        dateObject.setHours(23, 59, 59, 999)
        return dateObject;
    }

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Access form data for further processing

        const startDateFilter = options.startDate && options.startDate.format('DD-MM-YYYY');
        if (options.endDate == null) {
            options.endDate = options.startDate
        }

        const endDateFilter = options.endDate ? options.endDate.format('DD-MM-YYYY') : options.startDate.format('DD-MM-YYYY');;

        const startDate = getFormattedUTCDate(convertStringToDate(startDateFilter))
        const endDate = getFormattedUTCDate(convertStringToDateWithEndTime(endDateFilter))

        let _filters = { ...mainOptions };
        _filters.startDate = startDate;
        _filters.endDate = endDate;
        if (endDateString) {
            setSelectedPeriodName(startDateString + " to " + endDateString)
        } else {
            setSelectedPeriodName(startDateString)
        }
        setMainOptions(_filters)

        setmodal_center(!modal_center);
        removeBodyCss();
        let _options = { ...options };
        _options.startDate = null;
        _options.endDate = null;
        _options.focusedInput = 'startDate';

        setOptions(_options)
        setFontSize('font-size-18 pt-2 pb-1')

        // You can perform actions with formData here, like making an API call or updating state in the parent component.
    };


    var startDateString = options.startDate && options.startDate.format('MMM DD YYYY');
    var endDateString = options.endDate && options.endDate.format('MMM DD YYYY');

    return (
        <>
            <div className="d-flex flex-column justify-content-between mb-3">
                <div className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">

                        <Dropdown
                            isOpen={btnsecondary1}
                            toggle={() => setBtnsecondary1(!btnsecondary1)}
                            onChange={changePeriod}
                        >
                            <DropdownToggle caret tag="button" className="btn btn-primary" >
                                {selectedPeriodName ? selectedPeriodName : "This Month"} <i className="mdi mdi-chevron-down text-right" />
                            </DropdownToggle>
                            <DropdownMenu>
                                {filterPeriod.map((option, index) => (
                                    <DropdownItem key={index} onClick={() => changePeriod(option)}>
                                        {option.name}
                                    </DropdownItem>
                                ))}
                                {/* <DropdownItem onClick={changePeriod('all')} value='all'>Action</DropdownItem>
                                        <DropdownItem onClick={changePeriod('week')} value='week'>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem> */}
                                <div className="dropdown-divider"></div>
                                <DropdownItem onClick={tog_center}>Custom</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        {/* <Dropdown value={selectedPeriod} onChange={changePeriod} options={filterPeriod} optionLabel="name" className="h-1 payment-status-dropdown" /> */}

                    </div>
                </div>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} size={'lg'} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Payment Period</ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit}>

                        <div className="d-flex justify-content-around gap-2">
                            <div>
                                <DayPickerRangeController
                                    startDate={options.startDate} // momentPropTypes.momentObj or null,
                                    endDate={options.endDate} // momentPropTypes.momentObj or null,
                                    onDatesChange={onDatesChange} // PropTypes.func.isRequired,
                                    focusedInput={options.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={onFocusChange} // PropTypes.func.isRequired,
                                    isOutsideRange={isOutsideRange}
                                    numberOfMonths={2}
                                />
                                <Row className="align-items-center">
                                    <Col>
                                        <Input type="text" name="start_date" disabled placeholder="Start Date" value={startDateString} readOnly />
                                    </Col> to
                                    <Col>
                                        <Input type="text" name="end_date" disabled placeholder="End Date" value={endDateString} readOnly />
                                    </Col>
                                </Row>
                                {/* <Row>
                                {preset.map((data) => (
                                    <Col sm={6} key={data.name}>
                                        {data.name}
                                    </Col>
                                ))}
                            </Row> */}

                            </div>


                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end mt-3">
                            <Button type="submit" color="primary" disabled={!startDateString}>
                                Search
                            </Button>{" "}
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default PaymentPeriodModal
import React, { useEffect, useState } from "react";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import SectionTable from "./SectionTable";
import { useLocation } from "react-router-dom";
import useEducard from "hooks/useEducard";

const EducardSettings = () => {
    const location = useLocation();
    const propsData = location.state;
    const { setSections, setTotalRecords, totalRecords, setExpandedRows, refresh } = useEducard()

    const [lazyState, setlazyState] = useState({
        searchText: propsData || '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();

    const getEducards = async () => {
        try {
            const response = await axiosApi.get(`/api/admin/education/section?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);

            setSections(response.data.result)
            setTotalRecords(response.data.totalRecords)
            setLoading(false);
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                setExpandedRows(obj)
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getEducards();
        }, 300);
    }, [lazyState]);

    useEffect(() => {
        getEducards();
    }, [refresh]);
    return (
        <React.Fragment>
            <SectionTable loading={loading} id={propsData} lazyState={lazyState} setlazyState={setlazyState} />

        </React.Fragment>
    );
};

export default EducardSettings;

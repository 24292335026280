import { useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import { simplify, balanceFormatter, inputDateMonth, debounce } from "helpers/functions";
import { InputText } from 'primereact/inputtext';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import '../../../assets/css/style.css'
import { ROWS_PER_PAGE } from "constants/value";
import moment from "moment";
import useLeaderboard from "hooks/useLeaderboard";

const BonusHistoryModal = ({ data }) => {
    const { bonusHistory, bonusTotalRecords, modal_center, setmodal_center, bonusHistoryTitle } = useLeaderboard();
    const [search, setSearch] = useState('')
    const tog_center = () => {
        setmodal_center(!modal_center);
    };

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const initialLazyState = {
        searchText: '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const [loading, setLoading] = useState(false);
    const [lazyState, setlazyState] = useState(initialLazyState);
    const propsData = location.state;

    const items = Array.from({ length: 5 }, (v, i) => i);

    const delay = 300;

    const roundedVol = (data) => {
        return simplify(data)
    }

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }
    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
    const debouncedSearch = debounce(changeFilter, delay);

    const createdAt = (data) => {
        return moment(data.createdAt).format('MM/DD/YYYY HH:mm:ss')
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const columns = [
        { field: '_id', className: "bold", header: '#', body: incrementId },
        { field: '', header: 'Period', body: (data) => inputDateMonth(data.createdAt,'MM-YYYY') },
        { field: 'extraData.bonus_tier', header: 'Tier', body: (data) => data.extraData && data.extraData.bonus_tier ? data.extraData.bonus_tier : '-' },
        { field: 'extraData.bonus_volume', header: 'UCO collected (ltr)', body: (data) => data.extraData && data.extraData.bonus_volume ? roundedVol(data.extraData.bonus_volume) : 0 },
        { field: 'bonus', header: 'Bonus reward', body: (data) => balanceFormatter(data?.bonus, data.currency) || '-' },
    ]
    return (
        <>
            <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
                <ModalHeader className="mt-0" toggle={tog_center}>Bonus History | {bonusHistoryTitle} </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <Row className="align-items-center mb-2">
                                        <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                            {/* <h4 className="card-title mb-0">Broadcast Notification Logs</h4> */}
                                        </Col>
                                        {/* <Col md={5}>
                                            <span className="p-input-icon-left float-start float-md-end">
                                                <i className="pi pi-search" />
                                                <InputText value={search} onChange={onSearchTextChange} placeholder="Title / Message / Url" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                            </span>
                                        </Col> */}
                                        <Col md={1}>
                                            <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                                {/* <UserExportCSV lazyState={lazyState} /> */}
                                            </div>
                                        </Col>
                                    </Row>

                                    <DataTable
                                        lazy
                                        value={loading ? items : bonusHistory}
                                        stripedRows
                                        paginator
                                        rows={lazyState.config.rows}
                                        rowsPerPageOptions={ROWS_PER_PAGE}
                                        size={'normal'}
                                        onPage={onPage}
                                        totalRecords={bonusTotalRecords}
                                        first={lazyState.config.first}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                                        dataKey={loading ? '' : '_id'}
                                    >
                                        {columns.map((col, i) => (
                                            <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                                        ))}
                                    </DataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )

}

export default BonusHistoryModal

import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"

import moment from "moment";
import useAdmin from "hooks/useAdmin";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { Link } from "react-router-dom";

import { simplify, startTime, endTime, debounce, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
import TransactionHistoryExportCSV from "../exports/TransactionHistoryExportCSV";

const TransactionHistoryModal = ({ data, options }) => {
    // console.log('data',data);
    const initialLazyState = {
        searchText: '',
        startDate: options.startDate,
        endDate: options.endDate,
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [trans, setTrans] = useState([]);
    const [search, setSearch] = useState('')
    const [modal_center, setmodal_center] = useState(false);
    const [lazyState, setlazyState] = useState(initialLazyState);
    const [expandedRows, setExpandedRows] = useState(null);

    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const delay = 300;


    const duration = (data) => {
        return moment.duration(moment(data.TA_End_Time).diff(moment(data.TA_Start_Time))).humanize()
    }

    const roundedVol = (data) => {
        return simplify(data.TA_UCO_Volume)
    }

    const roundedWeight = (data) => {
        return simplify(data.TA_UCO_Weight)
    }

    const trxId = (data) => {
        return <Link to={'/transactions/' + data.TA_REF_ID} > {data.TA_REF_ID} </Link>

    }
    const totalReward = (data) => {
        return balanceFormatter(data?.totalAmount || 0, 'IDR')
    }


    const columns = [
        { field: '', header: 'Transaction ID', body: trxId },
        { field: 'TA_UCO_Volume', header: 'Volume (ltr)', body: roundedVol },
        { field: 'TA_UCO_Weight', header: 'Weight (kg)', body: roundedWeight },
        { field: 'TA_Start_Time', header: 'Date', body: startTime },
        { field: 'totalAmount', header: 'Reward', body: totalReward, sortable: true },

    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const getTransactionHistory = async () => {
        try {
            setLoading(true);
            // const response = await axiosApi.get(`/api/admin/marketing/promotion/${data?._id}/transactions?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`);
            const response = await axiosApi.get(`/api/admin/marketing/promotion/${data?._id}/transactions?skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`);
            const result = response.data.result;
            setTrans(result);
            setTotalRecords(response.data.totalRecords || 0)
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setlazyState(initialLazyState);
        setSearch('');
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        if (modal_center) {
            getTransactionHistory()
        };
    }, [modal_center, lazyState]);

    return (
        <>
            <div className="my-2">
                <a href="#" onClick={tog_center}>
                    {balanceFormatter(data.totalReward || 0, 'IDR')}
                </a>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
                <ModalHeader className="mt-0 with-close" toggle={tog_center}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="card-title m-0">Reward History | {data?.name}</h4>
                        <div className="d-flex align-items-center gap-3 me-2">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText value={search} onChange={onSearchTextChange} placeholder="ID" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                            </span>
                            <TransactionHistoryExportCSV lazyState={lazyState} data={data} />
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className="min-modal-body">
                    <DataTable
                        // lazy
                        removableSort
                        value={loading ? items : trans}
                        stripedRows
                        paginator
                        rows={lazyState.config.rows}
                        rowsPerPageOptions={ROWS_PER_PAGE}
                        size={'normal'}
                        // onPage={onPage}
                        totalRecords={totalRecords}
                        first={lazyState.config.first}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                    >
                        {columns.map((col, i) => (
                            <Column sortable={col.sortable} key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                        ))}
                    </DataTable>
                </ModalBody>
            </Modal>
        </>
    )
}

export default TransactionHistoryModal

import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePayment from "hooks/usePayment";
import moment from "moment";


const ExportCSV = ({ lazyState }) => {
    
    const { selectedPeriodName } = usePayment();
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Payment_Request_List_${lazyState.status}_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}_${selectedPeriodName}.csv`
    console.log('filename',filename);

    const headers = [
        { label: "Payment Request ID", key: "reqId" },
        { label: "User ID", key: "user_id" },
        { label: "User Name", key: "user[0].name" },
        { label: "Input Date Time", key: "createdAt" },
        { label: "Amount", key: "amount" },
        { label: "Currency", key: "currency" },
        { label: "Status", key: "status" },
        { label: "Method", key: "method" },
        { label: "Type", key: "type" },
        { label: "Account", key: "account" },
        { label: "Method Transaction ID", key: "mtId" },
        { label: "User Email", key: "user[0].email" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/admin/finance/export-payment-request?status=${lazyState.status}&searchText=${lazyState.searchText}&startDate=${lazyState.searchText == '' ? lazyState.startDate : ''}&endDate=${lazyState.searchText == '' ? lazyState.endDate : ''}`);

            const result = response.data.result;
            result.forEach(obj => {
                obj.createdAt = moment(obj.createdAt).format('MM/DD/YYYY HH:mm:ss');
            })
            setDataReq(result)
            setRefresh(response.data.random)
            setLoading(false)
            console.log(response.data)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default ExportCSV
import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import {
    currencyFormatter,
} from "helpers/functions"
const ConfirmationModal = ({ isOpen, toggle, onConfirm, data, type, rawData }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirm Your Settings</ModalHeader>
            <ModalBody>
                {type == 'withdrawal' &&
                    <div>
                        <div>
                            <h5>Withdrawal Limits</h5>
                            {rawData.withdrawal_limit.min.IDR == data.min_idr
                                ? <p> Minimum IDR : <b>{currencyFormatter(data.min_idr, 'IDR')}</b></p>
                                : <p>Minimum IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.min.IDR, 'IDR')}</s> → <span className='text-primary'>{currencyFormatter(data.min_idr, 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_limit.min.SGD == data.min_sgd
                                ? <p>Minimum SGD : <b>{currencyFormatter(data.min_sgd, 'SGD')}</b></p>
                                : <p>Minimum SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.min.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.min_sgd, 'SGD')}</span></p>
                            }
                            {rawData.withdrawal_limit.max.IDR == data.max_idr
                                ? <p>Maximum IDR : <b>{currencyFormatter(data.max_idr, 'IDR')}</b></p>
                                : <p>Maximum IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.max.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(data.max_idr, 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_limit.max.SGD == data.max_sgd
                                ? <p>Maximum SGD : <b>{currencyFormatter(data.max_sgd, 'SGD')}</b></p>
                                : <p>Maximum SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.max.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.max_sgd, 'SGD')}</span></p>
                            }
                        </div>
                        <div>
                            <h5>Withdrawal Thresholds</h5>
                            {rawData.withdrawal_threshold.min_withdraw_amount.IDR == data.withdrawal_threshold_min_idr
                                ? <p>Minimum Amount IDR : <b>{currencyFormatter(data.withdrawal_threshold_min_idr, 'IDR')}</b></p>
                                : <p>Minimum Amount IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.min_withdraw_amount.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(data.withdrawal_threshold_min_idr, 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_threshold.min_withdraw_amount.SGD == data.withdrawal_threshold_min_sgd
                                ? <p> Minimum Amount SGD : <b>{currencyFormatter(data.withdrawal_threshold_min_sgd, 'SGD')}</b></p>
                                : <p>Minimum Amount SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.min_withdraw_amount.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.withdrawal_threshold_min_sgd, 'SGD')}</span></p>
                            }
                            {rawData.withdrawal_threshold.withdraw_fee.IDR == data.withdrawal_threshold_fee_idr
                                ? <p> Withdraw Fee IDR : <b>{currencyFormatter(data.withdrawal_threshold_fee_idr, 'IDR')}</b></p>
                                : <p>Withdraw Fee IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.withdraw_fee.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(data.withdrawal_threshold_fee_idr, 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_threshold.withdraw_fee.SGD == data.withdrawal_threshold_fee_sgd
                                ? <p> Withdraw Fee SGD: <b>{currencyFormatter(data.withdrawal_threshold_fee_sgd, 'SGD')}</b></p>
                                : <p>Withdraw Fee SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.withdraw_fee.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.withdrawal_threshold_fee_sgd, 'SGD')}</span></p>
                            }
                        </div>
                        <div>
                            <h5>Auto Withdrawal</h5>
                            {rawData.auto_withdrawal == data.withdrawal
                                ? <p><b>{rawData.auto_withdrawal ? 'Enabled' : 'Disabled'}</b></p>
                                : <p><s className='text-danger'>{rawData.auto_withdrawal ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.withdrawal ? 'Enabled' : 'Disabled'}</span></p>
                            }
                        </div>
                    </div>
                }
                {type == 'general' &&
                    <div>
                        <div>
                            <h5>App Versions</h5>
                            {rawData.version.android == data.android_v
                                ? <p>Android Version : <b>{data.android_v}</b></p>
                                : <p>Android Version : <s className='text-danger'>{rawData.version.android}</s> → <span className='text-primary'>{data.android_v}</span></p>
                            }
                            {rawData.version.ios == data.ios_v
                                ? <p>iOS Version : <b>{data.ios_v}</b></p>
                                : <p>iOS Version : <s className='text-danger'>{rawData.version.ios}</s> →  <span className='text-primary'>{data.ios_v}</span></p>
                            }
                        </div>
                    </div>
                }
                {type == 'pickup' &&
                    <div>
                        <div>
                            <h5>Minimum Balance</h5>
                            {rawData.pickup.balance.min.IDR == data.min_balance_idr
                                ? <p>IDR : <b>{currencyFormatter(data.min_balance_idr, 'IDR')}</b></p>
                                : <p>IDR : <s className='text-danger'>{currencyFormatter(rawData.pickup.balance.min.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(data.min_balance_idr, 'IDR')}</span></p>}
                            {rawData.pickup.balance.min.SGD == data.min_balance_sgd
                                ? <p>SGD : <b>{currencyFormatter(data.min_balance_sgd, 'SGD')}</b></p>
                                : <p>SGD : <s className='text-danger'>{currencyFormatter(rawData.pickup.balance.min.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.min_balance_sgd, 'SGD')}</span></p>
                            }
                        </div>
                        <div>
                            <h5>Quantity (liter)</h5>
                            {rawData.pickup.quantity.min == data.min_qty
                                ? <p> Minimum : <b>{data.min_qty}</b></p>
                                : <p>Minimum : <s className='text-danger'>{rawData.pickup.quantity.min}</s> →  <span className='text-primary'>{data.min_qty}</span></p>
                            }
                            {rawData.pickup.quantity.max == data.max_qty
                                ? <p> Minimum : <b>{data.max_qty}</b></p>
                                : <p>Minimum : <s className='text-danger'>{rawData.pickup.quantity.max}</s> →  <span className='text-primary'>{data.max_qty}</span></p>
                            }
                        </div>
                        <div>
                            <h5>Maximum Distance (km): </h5>
                            {rawData.pickup.distance.max == data.max_distance
                                ? <p><b>{data.max_distance}</b></p>
                                : <p> <s className='text-danger'>{rawData.pickup.distance.max}</s>  →  <span className='text-primary'>{data.max_distance}</span></p>
                            }
                        </div>
                        <div>
                            <h5>Pick up Service (All User) </h5>
                            {rawData.pickup.service_available == data.pickupAvailable
                                ? <p><b>{data.pickupAvailable ? 'Enabled' : 'Disabled'}</b></p>
                                : <p><s className='text-danger'>{rawData.pickup.service_available ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.pickupAvailable ? 'Enabled' : 'Disabled'}</span></p>
                            }
                        </div>
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onConfirm}>Submit</Button>{' '}
                {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal

import React from "react"
import { Link } from "react-router-dom"
import AddPromotionModal from "./modals/AddPromotionModal";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";
import { useState } from "react";
import { InputText } from 'primereact/inputtext';
import { debounce,currencyFormatter } from "helpers/functions";
import { Divider } from 'primereact/divider';

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";

import avatar from "../../../../assets/images/users/user-blank.png";
import EditPromotionModal from "./modals/EditPromotionModal";
import DeletePromotionModal from "./modals/DeletePromotionModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import usePromotion from "hooks/usePromotion";
import PromotionExportCSV from "./exports/PromotionExportCSV";
import RegisteredUserModal from "./modals/RegisteredUserModal";

const PromotionModal = ({ id, promoLazyState, setPromoLazyState }) => {
    const [expandedRows, setExpandedRows] = useState(null);
    const [search, setSearch] = useState(id || '');
    const delay = 300;

    const { promotions, promotionLoading, countryList, promoTotalRecords, frequencyList } = usePromotion();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const changeFilter = (value) => {
        let _filters = { ...promoLazyState };
        _filters.searchText = value;
        setPromoLazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const countryData = (data) => {
        const countryObject = countryList.find(item => item.value === data.currency);
        const label = countryObject ? countryObject.label : '-';

        return label
    }

    const frequencyData = (data) => {
        const freqObject = frequencyList.find(item => item.value === data.frequency);
        const label = freqObject ? freqObject.label : '-';

        return label
    }
    const validityPeriod = (data) => {
        return data.start_date && data.end_date ? formatDate(data.timezone ?? 7, data.start_date) + ' - ' + formatDate(data.timezone ?? 7, data.end_date) : '-'
    }
    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditPromotionModal promotion={data} />
                </div>
                <div>
                    <DeletePromotionModal promotion={data} />
                </div>
            </div>
        )
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'name', header: 'Name', body: '' },
        { field: '', header: 'Frequency', body: frequencyData },
        { field: 'partner_name', header: 'Referrer / Partner', body: '' },
        // { field: 'type', header: 'Type', body: '' },
        { field: 'trigger', header: 'Trigger', body: '' },
        { field: 'referral_code', header: 'Referral Code', body: '' },
        { field: 'country', header: 'Country', body: countryData },
        { field: 'action', header: 'Action', body: adminAction },
    ]
    const onPage = (event) => {
        let _filters = { ...promoLazyState };
        _filters.config = event;
        setPromoLazyState(_filters);
    }
    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }
    function formatDate(offset, dateString) {
        let timezone = parseInt(offset) ?? 7; // Example timezone offset in hours (e.g., UTC+7)

        // Manually parse the date components
        let dateParts = dateString.split('T')[0].split('-');
        let timeParts = dateString.split('T')[1].split('.')[0].split(':');

        let year = parseInt(dateParts[0], 10);
        let month = parseInt(dateParts[1], 10);
        let day = parseInt(dateParts[2], 10);
        let hours = parseInt(timeParts[0], 10);
        hours += timezone;

        // Handle day/month/year overflow
        if (hours >= 24) {
            hours -= 24;
            day += 1;
            // Handle month overflow
            const daysInMonth = new Date(year, month, 0).getDate();
            if (day > daysInMonth) {
                day = 1;
                month += 1;
                // Handle year overflow
                if (month > 12) {
                    month = 1;
                    year += 1;
                }
            }
        }
        // Format the adjusted date into month/day/year
        let formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;

        return formattedDate;

    }
    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <div className="d-flex flex-row justify-content-around p-3">
                    <div>
                        <ul>
                            <li>Name : {data.name}</li>
                            <li>Frequency : {frequencyData(data)}</li>
                            <li>Type : {data.type}</li>
                            <li>Partner / Referrer : {data.partner_name}</li>
                            <li>Trigger : {data.trigger}</li>
                            <li>Referral Code : {data.referral_code}</li>
                            <li>Country : {countryData(data)}</li>
                            <li>Timezone : {data.timezone_name}</li>
                            {
                                data.type == 'User' ?
                                    <li>Description : {data.description ?? '-'}</li>
                                    :
                                    <li>Logo : {data.logo_url ? <img src={data.logo_url} width={80} height={80} alt="Logo" />
                                        : '-'}</li>
                            }


                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        <ul>
                            <li>Validity Period : {validityPeriod(data)}</li>
                            <li>Redemption Limit : {data.limit ?? 0} </li>
                            <li>Reward Amount ({data.currency}) : {currencyFormatter(data.amount ?? 0, data.currency)} </li>
                            <li>Reward Percentage : {data.percentage ?? 0} %</li>
                            <li>Minimum Deposit : {data.deposit ?? 0} L</li>
                            {data.trigger == 'Registration' && <li>Registrations : <RegisteredUserModal rowData={data} /></li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <AddPromotionModal />

                                {/* <h4 className="card-title mb-0">Users</h4> */}
                            </Col>
                            <Col md={5}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Name  / Description" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <PromotionExportCSV promoLazyState={promoLazyState} />
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            lazy
                            value={promotionLoading ? items : promotions}
                            stripedRows
                            paginator
                            rows={promoLazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={promoTotalRecords}
                            first={promoLazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            expandedRows={promotionLoading ? null : expandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                        // dataKey={promotionLoading ? '' : 'TA_ID'}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    body={promotionLoading ? <Skeleton></Skeleton> : col.body}
                                />
                            ))}
                        </DataTable>

                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default PromotionModal

import { createContext, useState } from "react";

const AdminContext = createContext({});

export const AdminProvider = ({children}) => {
    const [refresh, setRefresh] = useState('');
    const [admins, setAdmins] = useState();
    const [loading, setLoading] = useState(false);
    const levelOptions = [
        { label: "Admin", value: "superadmin" },
        { label: "Finance", value: "finance" },
        { label: "Engineer", value: "engineer" },
        { label: "Support", value: "support" }
    ];

    return (
        <AdminContext.Provider value={{refresh, setRefresh, levelOptions, admins, setAdmins, loading, setLoading}}>
            {children}
        </AdminContext.Provider>
    )
}

export default AdminContext;